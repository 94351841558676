import { useState } from 'react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';

import { SecurityDialog } from '@dx-ui/osc-marketing';

export function GatedModal({ domains, brandCode }: { domains?: string[]; brandCode: string }) {
  const [isVisible, setIsVisible] = useState(Array.isArray(domains) ? domains?.length > 0 : false);

  if (!Array.isArray(domains) || domains.length < 1) {
    return null;
  }

  return (
    <SecurityDialog
      content={<GatedModalContent domains={domains} onSubmit={() => setIsVisible(false)} />}
      isOpen={isVisible}
      size="sm"
      title="Exclusive Offers"
      themeWrapperProps={{ brandCodeForTheme: brandCode }}
    />
  );
}

type FormData = {
  email: string;
};

export function GatedModalContent({
  domains,
  onSubmit,
}: {
  domains: string[];
  onSubmit: () => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const handleEmailValidation = (email: string) => {
    return domains.some((domain) => email.toLowerCase().includes(domain.toLowerCase()));
  };

  return (
    <>
      {errors.email ? (
        <div id="email-error" className="bg-danger-alt flex justify-around p-2">
          <span className="text-danger mx-8 text-lg font-bold">
            Please enter a valid email or company code to access offers.
          </span>
        </div>
      ) : null}
      <p className="p-2 font-sans">
        Enter your employee email or company code to access exclusive discounts.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label
          aria-label="Enter your employee email or company code to access exclusive discounts."
          className={classnames('label w-full font-bold', { 'label-error': !!errors.email })}
          htmlFor="gated-email"
        >
          Email Address or Company Code
          <input
            aria-describedby={errors.email ? 'email-error' : undefined}
            aria-invalid={!!errors.email}
            className={classnames('form-input my-2 block', {
              'form-error': !!errors.email,
            })}
            id="gated-email"
            {...register('email', { required: true, validate: handleEmailValidation })}
            required
          />
        </label>

        <button className="btn btn-primary btn-2xl mb-4 block w-full" type="submit">
          Access Offers
        </button>
      </form>
    </>
  );
}
